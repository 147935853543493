@font-face {
  font-family: 'Overpass';
  font-weight: 400;
  src: url('./fonts/Overpass-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Overpass';
  font-weight: 500;
  src: url('./fonts/Overpass-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  src: url('./fonts/Overpass-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Overpass';
  font-weight: 300;
  src: url('./fonts/Overpass-Thin.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  /* scrollbar-color: #acacac40 transparent; */
}

body {
  font-family: 'Overpass', sans-serif !important;
}

div.MuiPaper-root:has(ul > li.study-center-select)::-webkit-scrollbar,
#menu-field_subcategories > div.MuiPaper-root::-webkit-scrollbar,
#menu-products_requested > div.MuiPaper-root::-webkit-scrollbar,
#menu-area > div.MuiPaper-root::-webkit-scrollbar,
#menu-service > div.MuiPaper-root::-webkit-scrollbar,
#menu-type > div.MuiPaper-root::-webkit-scrollbar,
#menu-field_material_types > div.MuiPaper-root::-webkit-scrollbar,
#navbar-fla-vertical::-webkit-scrollbar,
#navbar-fla-vertical::-webkit-scrollbar,
.css-kmjbct::-webkit-scrollbar,
.css-o6yh8o::-webkit-scrollbar,
.css-kkkl4u-MuiStack-root::-webkit-scrollbar,
.css-2vmj83::-webkit-scrollbar,
.css-1y1f0ew::-webkit-scrollbar {
  width: 6px;
  margin-right: 2px !important;
  background-color: transparent;
}

/* #navbar-fla-vertical {
  scrollbar-color: #63738148 transparent;
} */

div.MuiPaper-root:has(ul > li.study-center-select)::-webkit-scrollbar-thumb,
#menu-field_subcategories > div.MuiPaper-root::-webkit-scrollbar-thumb,
#menu-products_requested > div.MuiPaper-root::-webkit-scrollbar-thumb,
#menu-area > div.MuiPaper-root::-webkit-scrollbar-thumb,
#menu-service > div.MuiPaper-root::-webkit-scrollbar-thumb,
#menu-type > div.MuiPaper-root::-webkit-scrollbar-thumb,
#menu-field_material_types > div.MuiPaper-root::-webkit-scrollbar-thumb,
#navbar-fla-vertical::-webkit-scrollbar-thumb,
#navbar-fla-vertical::-webkit-scrollbar-thumb,
.css-kmjbct::-webkit-scrollbar-thumb,
.css-o6yh8o::-webkit-scrollbar-thumb,
.css-kkkl4u-MuiStack-root::-webkit-scrollbar-thumb,
.css-2vmj83::-webkit-scrollbar-thumb,
.css-1y1f0ew::-webkit-scrollbar-thumb {
  background: #63738148;
  border-radius: 1000px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 300px;
}

.react-parsed-text ol,
.react-parsed-text ul {
  padding-left: 0;
  margin-left: 10px;
}
.react-parsed-text ol {
  padding-left: 15px;
}
.react-parsed-text ul > li {
  margin-left: 10px;
}

.react-parsed-text a,
.react-parsed-text p > a {
  color: #d15d23;
}

.react-parsed-strong strong {
  color: black !important;
}

ul:has(> li.study-center-select) {
  max-height: 400px;
}

.apexcharts-yaxis-label {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
  border-radius: 1.84169px;
}

#chart-circularity-tool-tooltip {
  background: #ffffff;
  border-radius: 2.76254px;
  width: 60px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .date-range-picker-custom .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .css-z83vip-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-z83vip-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-z83vip-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100%;
  }
}
